const routes = [
  {
    path: "/ppb",
    id: "76e1ebfe-52ef-49c7-8679-c62bac5f81f3",
    groupId: "b59546a5-2998-404c-ad0e-c8229091f30c",
    scroll: true,
    width: "85%",
  },
  {
    path: "/cp-netflix",
    id: "d9c392d0-99dc-4074-8d20-712ad5adc6f7",
    groupId: "3157dfc0-2cce-4d9a-bf61-d4f9924bb0ec",
    scroll: true,
  },
  {
    path: "/NBCU",
    id: "1a973487-7e7b-469f-8782-9d4ade45949d",
    groupId: "0cb8ba98-c5d8-44df-b459-112039624de7",
    scroll: true,
  },
  {
    path: "/cp2",
    id: "f7415a64-8344-405d-b1a5-15a5f9329df3",
    groupId: "3157dfc0-2cce-4d9a-bf61-d4f9924bb0ec",
    scroll: true,
  },
  {
    path: "/cp",
    id: "32307f35-6ea6-48b4-a62c-6b8f18f8b676",
    groupId: "3157dfc0-2cce-4d9a-bf61-d4f9924bb0ec",
    scroll: true,
    width: "85%",
  },
  {
    path: "/cp-bis",
    id: "f5576a94-4153-465d-befb-a53792f3331a",
    groupId: "3157dfc0-2cce-4d9a-bf61-d4f9924bb0ec",
    scroll: true,
  },
  {
    path: "/cp/formatest",
    id: "e08df8d1-6e7d-4607-a530-0a778f59c5e7",
    groupId: "3157dfc0-2cce-4d9a-bf61-d4f9924bb0ec",
    scroll: true,
  },
  {
    path: "/oa",
    id: "eb780cb6-6b42-4541-969f-1832e9379fea",
    groupId: "44c0e0b1-8d2a-4ad2-8489-b53eac706b73",
  },
  {
    path: "/nmeupartner",
    id: "0d7eba8d-60d2-4833-b25a-a715dfb83d22",
    groupId: "8bf5a0c3-3266-48b4-9c7f-cbbf436e2b7e",
  },
  {
    path: "/nme",
    id: "40f7977f-cad8-4818-9874-0c38f96529b1",
    groupId: "b27ffaf4-5874-4e9b-875d-61b7acebc5b1",
    scroll: true
  },
  {
    path: "/ab",
    id: "4b1f3c60-9a66-47df-9828-c672dc4998f6",
    groupId: "0cb8ba98-c5d8-44df-b459-112039624de7",
  },
  {
    path: "/gaming",
    id: "61cf03a4-8c4b-4ddd-a5ef-bb71f235ff79",
    groupId: "790bdc65-6ecf-43d3-b4d8-992a490020a3",
  },
  {
    path: "/music",
    id: "98a6c17f-161f-400b-8458-587562800dee",
    groupId: "89988115-289d-4da0-921f-046280eda3fb",
  },
  {
    path: "/abv",
    id: "a129aa39-d444-48ce-bed0-8da059bec161",
    groupId: "ec2d7081-784f-4a6c-b73b-fab0fabb2747",
  },
  {
    path: "/ome",
    id: "b2b00b5b-ac27-4dfd-ae9b-c9c78663b453",
    groupId: "5b0d17f3-01fd-42c9-86ad-11d57c19d03c",
    navbar: false,
  },
  {
    path: "/gamingeng",
    id: "e323fe4b-c932-497b-a126-3006fc85ad9a",
    groupId: "790bdc65-6ecf-43d3-b4d8-992a490020a3",
  },
  {
    path: "/nmedtv",
    id: "844835f5-3c4f-4ef1-bfe0-0a0e0333e90e",
    groupId: "b27ffaf4-5874-4e9b-875d-61b7acebc5b1",
  },
  /* tablero Simple TV NME queda inactivo desde 28/04/24 --> NB 
{
    path: "/nmestv",
    id: "cd9530e6-4c81-49e5-be0f-6e4140d55be2",
    groupId: "5476087e-3f6a-488d-b535-6dd1a43fdfe3",
  },*/
  {
    path: "/trsw",
    id: "d46e4493-59ba-4173-8556-82538128ad9b",
    groupId: "0cb8ba98-c5d8-44df-b459-112039624de7",
  },
  {
    path: "/audiencias-wm",
    id: "ac0ecb8c-987f-41af-a39a-13dd5cadc86b",
    groupId: "0cb8ba98-c5d8-44df-b459-112039624de7",
  },
  {
    path: "/trsd",
    id: "81edc128-b8a5-4543-a857-f816c1c07668",
    groupId: "0cb8ba98-c5d8-44df-b459-112039624de7",
  },
  {
    path: "/pe",
    id: "3202fe9a-9826-49af-8d4f-816e8958e764",
    groupId: "b86148b0-c8fb-4bbe-87a5-142d4f2c8594",
    scroll: true,
  },

  {
    path: "/abdgo",
    id: "826c689b-3c1b-41ca-9eac-f0049f1edf3a",
    groupId: "0cb8ba98-c5d8-44df-b459-112039624de7",
  },
  {
    path: "/ct2",
    id: "ae3aebd6-8783-41f4-bc0b-f846f1a0b701",
    groupId: "d5a3b2fd-3b0e-4dc0-8008-b3db6716ecda",
    navbar: false,
  },
  {
    path: "/hits_local",
    id: "25b34285-e50a-4476-af93-309d2db482b2",
    groupId: "32d6eb9b-b6a4-429a-b462-bb9d94ab9672",
    scroll: false,
    navbar: false,
  },
  {
    path: "/hits",
    id: "79b0b87a-61ac-4f13-9638-fad771c61cbe",
    groupId: "32d6eb9b-b6a4-429a-b462-bb9d94ab9672",
    scroll: true,
    width: "85%",
    backgroundColor: "#E8E8EC"
  },
  {
    path: "/hits/lite",
    id: "68c103c5-1131-4d63-af1f-ae73af2c2f34",
    groupId: "83846efa-5904-4e90-af98-1d3eed458ac6",
    scroll: true,
    width: "85%",
    backgroundColor: "#E8E8EC"
  },
  {
    path: "/hits_test",
    id: "0ebd2d88-cfa3-41be-96e7-93621b30e670",
    groupId: "32d6eb9b-b6a4-429a-b462-bb9d94ab9672",
    scroll: false,
    navbar: false,
  },
  {
    path: "/hits-talent-mx-es",
    id: "4768c7b7-ac55-4b59-8ce4-95f7fd379a89",
    groupId: "32d6eb9b-b6a4-429a-b462-bb9d94ab9672",
    scroll: false,
    navbar: false
  },
  {
    path: "/hits-talent-mx",
    id: "ff72af60-98e8-4e91-bbc8-6fb855b87b75",
    groupId: "32d6eb9b-b6a4-429a-b462-bb9d94ab9672",
    scroll: false,
    navbar: false
  },
  {
    path: "/hits-talent-mx-en",
    id: "e3dce287-01ef-4c8d-a726-6791c67ad0a0",
    groupId: "32d6eb9b-b6a4-429a-b462-bb9d94ab9672",
    scroll: false,
    navbar: false
  },
  {
    path: "/hits-talent-br-pt",
    id: "bf475fd8-b07a-4820-b39a-6dbb23b12a72",
    groupId: "32d6eb9b-b6a4-429a-b462-bb9d94ab9672",
    scroll: false,
    navbar: false
  },
  {
    path: "/hits-talent-br-en",
    id: "09e37198-fd8a-49ff-8d57-d70a2c59540b",
    groupId: "32d6eb9b-b6a4-429a-b462-bb9d94ab9672",
    scroll: false,
    navbar: false
  },
  {
    path: "/trsdp",
    id: "f069bf02-b177-4ec8-bf8c-d011cd38eedd",
    groupId: "ec2d7081-784f-4a6c-b73b-fab0fabb2747",
  },
  {
    path: "/st",
    id: "6718fcca-7a64-4558-90db-652dbfe772ab",
    groupId: "371b6ef7-c5d4-4e87-a2e0-b9e262d119e7",
    scroll: true,
    width: "85%",
  },
  {
    path: "/fp",
    id: "59997646-9060-450e-b8b2-aaa731df3f5e",
    groupId: "36df493c-c067-4190-9b28-f0fe178045e9",
    scroll: true,
  },
  {
    path: "/cpa2",
    id: "9eded4c5-f363-47a0-b91f-cb806c6ad6b3",
    groupId: "83846efa-5904-4e90-af98-1d3eed458ac6",
    scroll: true,
  },
  {
    path: "/ls",
    id: "9d6d1e72-4a21-48e0-90b1-1338424b1016",
    groupId: "c9771405-7259-495c-966e-7ac011ed6d0f",
    scroll: true,
    width: "85%",
  },
  {
    path: "/pea",
    id: "f3143db4-2d8f-4b7c-bfb8-7303772e996b",
    groupId: "83846efa-5904-4e90-af98-1d3eed458ac6",
  },
  {
    path: "/event-tracker",
    id: "df264b69-0afb-4e08-b92f-e917b445d6d6",
    groupId: "420f6728-c610-429f-8490-51733d3111a0",
    scroll: true,
  },
  {
    path: "/etmobile",
    id: "1d0b8b27-0e11-4160-bd5b-a25e98663bd3",
    groupId: "420f6728-c610-429f-8490-51733d3111a0",
    scroll: true,
  },
  {
    path: "/tlf",
    id: "6a7538c8-5aff-46fe-b394-2a0286441395",
    groupId: "51a5f488-be90-4043-9628-c38c0938d65c",
  },
  {
    path: "/metrics",
    id: "62b0375f-ce6f-4ff6-8093-cc66377aa9d5",
    groupId: "0ee26eaa-a06f-4d85-8055-2620e7328f54",
  },
  {
    path: "/trsm",
    id: "da9f3264-c005-4677-9a37-b140b41d6874",
    groupId: "ec2d7081-784f-4a6c-b73b-fab0fabb2747",
  },
  {
    path: "/cpba",
    id: "e77ceb81-18aa-479a-b8b7-3aa58f89a260",
    groupId: "3157dfc0-2cce-4d9a-bf61-d4f9924bb0ec",
  },
  {
    path: "/trsivc",
    id: "04d815e9-cc44-49d3-8272-473321082509",
    groupId: "ec2d7081-784f-4a6c-b73b-fab0fabb2747",
  },
  {
    path: "/trsws",
    id: "570ea3e5-af97-4eb7-aa92-3cc034c810f7",
    groupId: "4f4c32e4-65a9-45eb-8df2-61d4887e8c93",
  },
  {
    path: "/trsmt",
    id: "adfa6c85-a50f-4db1-9891-40b6d09a7a1d",
    groupId: "ec2d7081-784f-4a6c-b73b-fab0fabb2747",
  },
  {
    path: "/sportsgral",
    id: "c03eac63-4020-4617-a5dc-eaba10763ac6",
    groupId: "4f4c32e4-65a9-45eb-8df2-61d4887e8c93",
  },
  {
    path: "/sportsdm",
    id: "d6d5c0cf-7404-41ea-8009-113859809e69",
    groupId: "4f4c32e4-65a9-45eb-8df2-61d4887e8c93",
  },
  {
    path: "/sportst",
    id: "315c3f15-a331-4e24-9d41-e1357c7f6562",
    groupId: "4f4c32e4-65a9-45eb-8df2-61d4887e8c93",
  },
  {
    path: "/trsstv",
    id: "7b2172a9-ee82-4b77-a0fe-4680da1960ea",
    groupId: "ec2d7081-784f-4a6c-b73b-fab0fabb2747",
  },
  {
    path: "/trsh",
    id: "049487e3-2169-450a-88b0-e8a5aba0afcf",
    groupId: "ec2d7081-784f-4a6c-b73b-fab0fabb2747",
  },
  {
    path: "/trsnet",
    id: "0f133248-6ad5-4681-87be-b0fe0e218189",
    groupId: "ec2d7081-784f-4a6c-b73b-fab0fabb2747",
  },
  {
    path: "/cptest",
    id: "51f2f445-699e-41cf-bfde-c9d10f821790",
    groupId: "3157dfc0-2cce-4d9a-bf61-d4f9924bb0ec",
  },
  {
    path: "/trsamc",
    id: "967e71ac-e8e4-4d5a-84ca-300d8d05d00d",
    groupId: "0cb8ba98-c5d8-44df-b459-112039624de7",
  },
  {
    path: "/omechurn",
    id: "0238ddcc-7f1c-4ea7-b840-3c5b5ce12b7a",
    groupId: "5b0d17f3-01fd-42c9-86ad-11d57c19d03c",
    scroll: true,
  },
  {
    path: "/cv",
    id: "a4924dec-264a-45fd-abeb-ff46a60d1639",
    groupId: "bccac405-931d-44bb-b72b-1c0c280f9bd2",
  },
{
  path: "/auditv",
    id: "bb15c892-1029-4f62-bb84-a067cdcee108",
    groupId: "1474ab06-313d-4da3-b42b-da5dfcb327c7",
    scroll: true,
    navbar: false,
 },
  {
  path: "/alademo",
    id: "21586516-4371-427b-b533-119d09ea6ed4",
    groupId: "0cb8ba98-c5d8-44df-b459-112039624de7",
    scroll: true,
    navbar: false,
 },
  {
  path: "/alademoven",
    id: "1dcf9009-9015-4d69-8b4e-3198af273dc2",
    groupId: "ec2d7081-784f-4a6c-b73b-fab0fabb2747",
    scroll: true,
    navbar: false,
 },
  {
    path: "/voicew",
    id: "5b404868-e816-4cf4-8073-4a986f017505",
    groupId: "ddaa9959-3b31-4b3e-b1a5-d4961459c26b",
    scroll: true,
  },
{
    path: "/estudiosimpletv",
    id: "e9512a70-53d9-4840-9cb1-bfb6d331829b",
    groupId: "b27ffaf4-5874-4e9b-875d-61b7acebc5b1",
  },
{
    path: "/testjuanma",
    id: "5acedf39-cc74-463b-bfe4-8d28a5bebc31",
    groupId: "4f4c32e4-65a9-45eb-8df2-61d4887e8c93",
  },
];

export const getDuplicates = (routes, type) => {
  const duplicate = [];
  const arr = [];
  routes.forEach((route) => {
    if (arr.includes(route[type])) {
      duplicate.push(route);
    } else {
      arr.push(route[type]);
    }
  });
  return duplicate;
};

export default routes;
